import { render, staticRenderFns } from "./DButton.vue?vue&type=template&id=f10f1454&scoped=true"
import script from "./DButton.vue?vue&type=script&lang=js"
export * from "./DButton.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f10f1454",
  null
  
)

export default component.exports