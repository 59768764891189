<template>
    <div>
        <div class="order-card" :class="`${ selected ? 'order-card-selected' :'' }`"
             @click="select">
            <div class="clinics dark--text">{{ item.clinics }}</div>
            <div class="name mt-3">Shifokor F.I.SH</div>
            <div class="doctor-last-name mt-2">{{ item.doctor.last_name }}</div>
            <div class="doctor-first-name mt-2">{{ item.doctor.first_name }}</div>
            <div class="doctor-type mt-3 dark--text">{{ item.doctor.doctor_type }}</div>
            <div class="doctor-region mt-4 dark--text">{{ item.doctor.region }}, {{ item.doctor.district }}</div>
            <div class="time mt-4 dark--text">{{ item.created_at }}</div>
        </div>

    </div>
</template>

<script>
export default {
    name: "OrderCard",
    methods: {
        select() {
            if (!this.selected)
                this.$router.push({name: 'recipes.show', params: {id: this.item.patient_id, recipe_number: this.item.id}, query:{order_id: this.$route.query.order_id}})
            else
                this.$parent.$parent.$parent.$parent.snackbar = true
        }
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        selected: {
            type: Boolean,
            required: false,
            defaultValue: false
        }
    }
}
</script>

<style scoped>

</style>