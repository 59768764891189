import axios from 'axios'
import router from "@/router";

const state = {
    doctor: {
        phone_number: "+998"
    },
    doctor_types: {},
    doctor_loading: false,
    speech_to_text: false,
    useful_text: false,
    token: "",
    doctor_errors: {},
    permissions: []
}

const getters = {
    doctor: state => state.doctor,
    token: state => state.token,
    permissions: state => state.permissions,
    speech_to_text: state => state.speech_to_text,
    useful_text: state => state.useful_text,
    doctor_types: state => state.doctor_types,
    doctor_loading: state => state.doctor_loading,
    doctor_errors: state => state.doctor_errors,
}

const mutations = {
    setDoctor(state, doctor) {
        state.doctor = doctor
    },
    setToken(state, token) {
        state.token = token
    },
    setSpeechToText(state, speech_to_text) {
        state.speech_to_text = speech_to_text
    },
    setUsefulText(state, useful_text) {
        state.useful_text = useful_text
    },
    setPermissions(state, permissions) {
        state.permissions = permissions
    },
    setDoctorTypes(state, doctor_types) {
        state.doctor_types = doctor_types
    },
    setDoctorLoading(state, loading) {
        state.doctor_loading = loading
    },
    setDoctorErrors(state, errors) {
        state.doctor_errors = errors
    },
}

const actions = {
    getDoctorTypes({commit}, params) {
        axios.get('/api/doctor-types', {params})
            .then(response => {
                commit('setDoctorTypes', response.data)
            })
    },
    async checkPhone({commit}, data) {
        commit('setDoctorLoading', true)
        commit('setDoctorErrors', {})
        try {
            let response = await axios.post('/api/doctors/check-phone-number', data)
            commit('setDoctorLoading', false)
            return response.data
        } catch (error) {
            console.log(error);
            commit('setDoctorLoading', false)
            commit('setDoctorErrors', error.response.data.errors)
            return false
        }
    },
    async logout({commit, getters}) {
        commit('setDoctorLoading', true)
        commit('setDoctorErrors', {})
        try {
            let token = getters.token
            let response = await axios.post('/api/logout', {}, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
            commit('setDoctorLoading', false)
            if (response.data.ok) {
                var doctor = {}
                window.localStorage.clear()
                commit('setDoctor', doctor)
                return true
            } else {
                return false
            }
        } catch (error) {
            console.log(error);
            commit('setDoctorLoading', false)
            commit('setDoctorErrors', error.response.data.errors)
            return false
        }
    },
    async check_me({commit,getters}) {
        commit('setDoctorLoading', true)
        commit('setDoctorErrors', {})
        try {
            let token = getters.token
            let response = await axios.get('/api/doctors/me', {}, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
            commit('setDoctorLoading', false)
            if (response.data.ok) {
                commit('setDoctor', response.data.data)
                commit('setPermissions', response.data.data.permissions)

                window.localStorage.setItem('doctor', JSON.stringify(response.data.data))
                return {
                    ok: true, status: response.status
                }
            } else {
                return {ok: false, status: response.status}
            }
        } catch (error) {
            commit('setDoctorLoading', false)
            if (error.response.data && error.response.data.errors)
                commit('setDoctorErrors', error.response.data.errors)
            return {ok: false, status: error.response.status}
        }
    },
    async storeDoctor({commit}, data) {
        commit('setDoctorLoading', true)
        commit('setDoctorErrors', {})

        try {
            let response = await axios.post('/api/doctors/register', data)
            commit('setDoctorLoading', false)
            window.localStorage.setItem('doctor', JSON.stringify(response.data.user))
            window.localStorage.setItem('token', response.data.token)
            return response.data.ok
        } catch (error) {
            console.log(error);
            commit('setDoctorLoading', false)
            commit('setDoctorErrors', error.response.data.errors)
            return false
        }
    },
    async checkRegion({commit}, data) {
        commit('setDoctorLoading', true)
        commit('setDoctorErrors', {})
        try {
            let response = await axios.post('/api/doctors/check-region', data)
            commit('setDoctorLoading', false)
            if (response.data.ok) {
                return true
            } else {
                return false
            }
        } catch (error) {
            console.log(error);
            commit('setDoctorLoading', false)
            commit('setDoctorErrors', error.response.data.errors)
            return false
        }
    },
    async check_login({commit}, data) {
        commit('setDoctorErrors', {})
        try {
            let response = await axios.post('/api/doctors/check-login', data)
            commit('setDoctorLoading', false)
            if (response.data.ok) {
                commit('setDoctor', response.data.doctor)
                return true
            } else {
                return false
            }
        } catch (error) {
            console.log(error);
            commit('setDoctorErrors', error.response.data.errors)
            return false
        }
    },
    async login({commit, getters}, data) {
        commit('setDoctorLoading', true)
        commit('setDoctorErrors', {})
        try {
            let response = await axios.post('/api/doctors/login', data)
            commit('setDoctorLoading', false)
            if (response.data.ok) {
                commit('setToken', response.data.token)
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + getters.token;
                commit('setDoctor', response.data.doctor)
                router.push("/profile").then(()=>{
                    window.location.reload()
                })
                return true
            } else {
                return false
            }
        } catch (error) {
            console.log(error);
            commit('setDoctorLoading', false)
            commit('setDoctorErrors', error.response.data.errors)
            return false
        }
    },
    async forgotPassword({commit}, data) {
        commit('setDoctorLoading', true)
        commit('setDoctorErrors', {})
        try {
            let response = await axios.post('/api/doctors/forgot-password', data)
            commit('setDoctorLoading', false)
            if (response.data.ok) {
                var doctor = state.doctor
                doctor.phone_number = data.phone_number
                commit('setDoctor', doctor)
                return true
            } else {
                return false
            }
        } catch (error) {
            console.log(error);
            commit('setDoctorLoading', false)
            commit('setDoctorErrors', error.response.data.errors)
            return false
        }
    },
    async code({commit}, data) {
        commit('setDoctorLoading', true)
        commit('setDoctorErrors', {})
        try {
            let response = await axios.post('/api/doctors/code', data)
            commit('setDoctorLoading', false)
            if (response.data.ok) {
                var doctor = state.doctor
                doctor.phone_number = data.phone_number
                doctor.code = data.code
                commit('setDoctor', doctor)
                return true
            } else {
                return false
            }
        } catch (error) {
            console.log(error);
            commit('setDoctorLoading', false)
            commit('setDoctorErrors', error.response.data.errors)
            return false
        }
    },
    async resetPassword({commit}, data) {
        commit('setDoctorLoading', true)
        commit('setDoctorErrors', {})
        try {
            let response = await axios.post('/api/doctors/reset-password', data)
            commit('setDoctorLoading', false)
            if (response.data.ok) {
                commit('setDoctor', response.data.doctor)
                window.localStorage.setItem('token', response.data.token)
                window.localStorage.setItem('doctor', JSON.stringify(response.data.user))
                return true
            } else {
                return false
            }
        } catch (error) {
            console.log(error);
            commit('setDoctorLoading', false)
            commit('setDoctorErrors', error.response.data.errors)
            return false
        }
    },


}

export default {
    state,
    getters,
    mutations,
    actions
}