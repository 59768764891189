const state = {
    search_type: "",
    selected_drug_company: {},
    selected_drug_type: {},
    selected_country: {},
    is_discount: {},
    price_from: "0",
    price_to: "0",
    selected_mnn: {},
}

const getters = {
    search_type: state => state.search_type,
    selected_country: state => state.selected_country,
    selected_drug_company: state => state.selected_drug_company,
    selected_drug_type: state => state.selected_drug_type,
    is_discount: state => state.is_discount,
    price_from: state => state.price_from,
    price_to: state => state.price_to,
    selected_mnn: state => state.selected_mnn,
}

const mutations = {
    setSearchType(state, search_type) {
        state.search_type = search_type
    },
    setSelectedCountry(state, selected_country) {
        state.selected_country = selected_country
    },
    setSelectedDrugCompany(state, selected_drug_company) {
        state.selected_drug_company = selected_drug_company
    },
    setSelectedDrugType(state, selected_drug_type) {
        state.selected_drug_type = selected_drug_type
    },
    setIsDiscount(state, is_discount) {
        state.is_discount = is_discount
    },
    setPriceFrom(state, price_from) {
        state.price_from = price_from
    },
    setPriceTo(state, price_to) {
        state.price_to = price_to
    },
    setSelectedMnn(state, selected_mnn) {
        state.selected_mnn = selected_mnn
    }
}

const actions = {}

export default {
    state,
    getters,
    mutations,
    actions
}