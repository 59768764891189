import axios from 'axios'

const state = {
    patient: {},
    patients: {},
    patient_history: {},
    patients_loading: false,
    patients_errors: {},
    patients_error_message: '',
}

const getters = {
    patient: state => state.patient,
    patients: state => state.patients,
    patients_loading: state => state.patients_loading,
    patient_history: state => state.patient_history,
    patients_errors: state => state.patients_errors,
    patients_error_message: state => state.patients_error_message,
}

const mutations = {
    setPatient(state, patient) {
        state.patient = patient
    },
    setPatients(state, patients) {
        state.patients = patients
    },
    setPatientsLoading(state, loading) {
        state.patients_loading = loading
    },
    setPatientDrugs(state, drugs) {
        state.patient_drugs = drugs
    },
    setPatientsErrors(state, errors) {
        state.patients_errors = errors
    },
    setPatientsErrorMessage(state, message) {
        state.patients_error_message = message
    },
    setPatientHistory(state, patient_history) {
        state.patient_history = patient_history
    },
}

const actions = {
    getPatients({commit}, params) {
        commit('setPatientsLoading', true)
        commit('setPatients', {})
        axios.get('/api/doctors/patients', {params}).then(response => {
            commit('setPatients', response.data)
            commit('setPatientsLoading', false)
        })
    },
    getPatientHistory({commit}, id) {
        commit('setPatientsLoading', true)
        commit('setPatients', {})
        axios.get('/api/doctors/patients/' + id + "/history").then(response => {
            commit('setPatientHistory', response.data)
            commit('setPatientsLoading', false)
        })
    },
    getPatientOrders({commit}, {id, params}) {
        commit('setOrders', {})
        commit('setOrdersLoading', true)
        axios.get('/api/doctors/patients/' + id + "/orders", {params}).then(response => {
            commit('setOrders', response.data)
            commit('setOrdersLoading', false)
        })
    },
    async getPatient({commit}, id) {

        commit('setPatientsLoading', true)
        commit('setPatient', {})
        try {
            var response = await axios.get('/api/doctors/patients/' + id + '/show')
            commit('setPatient', response.data.data)
            commit('setPatientsLoading', false)
            return true
        } catch (e) {
            commit('setPatientsErrors', e.response.data.errors)
            commit('setPatientsErrorMessage', e.response.data.message)
            return false
        }
    },

    async storePatient({commit}, patient) {
        commit('setPatientsLoading', true)
        try {

            var response = await axios.post('/api/doctors/patients/store', patient)
            if (!response.data.ok)
                commit('setPatientsErrors', response.data.errors)
            patient.is_creating = false
            commit('setPatient', patient)
            return response.data.ok
        } catch (e) {
            commit('setPatientsErrors', e.response.data.errors)
            commit('setPatientsErrorMessage', e.response.data.message)
            return false
        }
    },

    async storePatientWithoutInfo({commit}, patient) {
        commit('setPatientsLoading', true)
        try {

            var response = await axios.post('/api/doctors/patients/store-without-info', patient)
            if (!response.data.ok)
                commit('setPatientsErrors', response.data.errors)

            return response.data.ok
        } catch (e) {
            commit('setPatientsErrors', e.response.data.errors)
            commit('setPatientsErrorMessage', e.response.data.message)
            return false
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}