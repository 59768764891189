const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('@/pages/Index/IndexPage.vue'),
        meta: {
            requiresAuth: false,
            layout: 'base',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/all',
        name: 'index.all',
        component: () => import('@/pages/Doctor/IndexPage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'content',
            title: 'Barcha dorilar',
        }
    },
    {
        path: '/drugs',
        name: 'drugs.index',
        component: () => import('@/pages/Doctor/DrugIndex.vue'),
        meta: {
            navigation: 'drugs',
            requiresAuth: true,
            layout: 'content',
            title: 'Dorilar',
        }
    },
    {
        path: '/patients',
        name: 'patients.index',
        component: () => import('@/pages/Patient/PatientPage.vue'),
        meta: {
            navigation: 'patients',
            requiresAuth: true,
            layout: 'content',
            title: 'Bemorlar',
        }
    },
    {
        path: '/patients/:id',
        name: 'patients.show',
        component: () => import('@/pages/Patient/PatientShowPage.vue'),
        meta: {
            navigation: "patients",
            requiresAuth: true,
            layout: 'content',
            title: 'Bemorlar',
        }
    },
    {
        path: '/orders/:id',
        name: 'orders.show',
        component: () => import('@/pages/Patient/OrderShowPage.vue'),
        meta: {
            navigation: "orders",
            requiresAuth: true,
            layout: 'content',
            title: 'Bemorlar',
        }
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import('@/pages/Doctor/ProfilePage.vue'),
        meta: {
            navigation: 'profile',
            requiresAuth: true,
            layout: 'content',
            title: 'Profil',
        }
    },
    {
        path: '/notifications',
        name: 'notifications',
        component: () => import('@/pages/Notification/IndexPage.vue'),
        meta: {
            navigation: 'notifications',
            requiresAuth: true,
            layout: 'content',
            title: 'Xabarnomalar',
        }
    },
    {
        path: '/notifications/:id',
        name: 'notifications.show',
        component: () => import('@/pages/Notification/ShowPage.vue'),
        meta: {
            navigation: 'notifications',
            requiresAuth: true,
            layout: 'content',
            title: 'Xabarnomalar',
        }
    },
    {
        path: '/samples',
        name: 'samples',
        component: () => import('@/pages/Sample/IndexPage.vue'),
        meta: {
            navigation: 'samples',
            requiresAuth: true,
            layout: 'content',
            title: 'Xabarnomalar',
        }
    },
    {
        path: '/samples/:id',
        name: 'samples.show',
        component: () => import('@/pages/Sample/ShowPage.vue'),
        meta: {
            navigation: 'samples',
            requiresAuth: true,
            layout: 'content',
            title: 'Xabarnomalar',
        }
    },
    {
        path: '/saved',
        name: 'saved',
        component: () => import('@/pages/Saved/IndexPage.vue'),
        meta: {
            requiresAuth: true,
            navigation: 'drugs',
            layout: 'content',
            title: 'Saqlangan dorilar',
        }
    },
    {
        path: '/saved-for-layout',
        name: 'saved_for_layout',
        component: () => import('@/pages/Saved/IndexPage.vue'),
        meta: {
            requiresAuth: true,
            navigation: 'drugs',
            layout: 'content',
            title: 'Saqlangan dorilar',
        }
    },
    {
        path: '/layouts',
        name: 'layouts',
        component: () => import('@/pages/Layout/IndexPage.vue'),
        meta: {
            navigation: "layouts",
            requiresAuth: true,
            layout: 'content',
            title: 'Mening shablonlarim',
        }
    },
    {
        path: '/layouts/:id/edit',
        name: 'layouts.edit',
        component: () => import('@/pages/Layout/EditPage.vue'),
        meta: {
            navigation: "layouts",
            requiresAuth: true,
            layout: 'content',
            title: 'Shablonni tahrirlash',
        }
    },
    {
        path: '/recipes/:id/:recipe_number/show',
        name: 'recipes.show',
        component: () => import('@/pages/Patient/EditRecipe.vue'),
        meta: {
            navigation: "patients",
            requiresAuth: true,
            layout: 'recipe',
            title: 'Retsept',
        }
    },
    {
        path: '/recipes/create/:id',
        name: 'recipes.create',
        component: () => import('@/pages/Patient/CreateRecipe.vue'),
        meta: {
            navigation: "patients",
            requiresAuth: true,
            layout: 'recipe',
            title: 'Retsept yaratish',
        }
    },
    {
        path: '/statistics',
        name: 'statistics',
        component: () => import('@/pages/Statistics/IndexPage.vue'),
        meta: {
            navigation: "statistics",
            requiresAuth: true,
            layout: 'content',
            title: 'Hisobotlar',
        }
    },
    {
        path: '/impersonate',
        name: 'impersonate',
        component: () => import('@/pages/Page/ImpersonatePage.vue'),
        meta: {
            navigation: "statistics",
            requiresAuth: true,
            layout: 'content',
            title: 'Hisobotlar',
        }
    },
    {
        path: '/statistics/drugs/:id',
        name: 'statistics.drugs.show',
        component: () => import('@/pages/Statistics/ShowPage.vue'),
        meta: {
            navigation: "statistics",
            requiresAuth: true,
            layout: 'content',
            title: 'Hisobotlar',
        }
    },
    {
        path: "*",
        component: () => import('@/pages/Page/PageNotFound.vue'),
    },

    {
        path: '/register',
        name: 'register',
        component: () => import('@/pages/Doctor/RegisterPage.vue'),
        meta: {
            requiresAuth: false,
            layout: 'register',
            title: 'Ro\'yxatdan o\'tish',
        }
    },
    {
        path: '/register/region',
        name: 'register.region',
        component: () => import('@/pages/Doctor/RegisterRegionPage.vue'),
        meta: {
            requiresAuth: false,
            layout: 'register',
            title: 'Ro\'yxatdan o\'tish',
        }
    },
    {
        path: '/register/doctor',
        name: 'register.doctor',
        component: () => import('@/pages/Doctor/RegisterDoctorPage.vue'),
        meta: {
            requiresAuth: false,
            layout: 'register',
            title: 'Ro\'yxatdan o\'tish',
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/pages/Doctor/LoginPage.vue'),
        meta: {
            requiresAuth: false,
            layout: 'register',
            title: 'Kirish',
        }
    },
    {
        path: '/forgot-password',
        name: 'forgot_password',
        component: () => import('@/pages/Doctor/ForgotPassword.vue'),
        meta: {
            requiresAuth: false,
            layout: 'register',
            title: 'Parolni tiklash',
        },
    },
    {
        path: '/password-code',
        name: 'password_code',
        component: () => import('@/pages/Doctor/PasswordCode.vue'),
        meta: {
            requiresAuth: false,
            layout: 'register',
            title: 'Parolni tiklash',
        }
    },
    {
        path: '/new-password',
        name: 'new_password',
        component: () => import('@/pages/Doctor/NewPassword.vue'),
        meta: {
            requiresAuth: false,
            layout: 'register',
            title: 'Parolni tiklash',
        }
    },
    {
        path: '/not-auth',
        name: 'not_auth',
        meta: {
            layout: 'content',
            requiresAuth: false,
            title: '401',
        },
        component: () => import('@/pages/Page/PageNotAllowed.vue'),
    },
    {
        path: '/not-yet',
        name: 'not_yet',
        meta: {
            layout: 'blank',
            requiresAuth: false,
            title: "So'rov yuborildi",
        },
        component: () => import('@/pages/Page/PageNotAllowed2.vue'),
    },
    {
        path: '/privacy-policy',
        name: 'privacy_policy',
        component: () => import('@/pages/Page/PrivacyPolicyPage.vue'),
        meta: {
            requiresAuth: false,
            layout: 'blank',
            title: 'Maxfiylik siyosati',
        }
    },


]

export default routes