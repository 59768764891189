import axios from 'axios'

const state = {
    order: {},
    orders: {},
    orders_loading: false,
    orders_errors: {},
    orders_error_message: '',
}

const getters = {
    order: state => state.order,
    orders: state => state.orders,
    orders_loading: state => state.orders_loading,
    orders_errors: state => state.orders_errors,
    orders_error_message: state => state.orders_error_message,
}

const mutations = {
    setOrder(state, order) {
        state.order = order
    },
    setOrders(state, orders) {
        state.orders = orders
    },
    setOrdersLoading(state, loading) {
        state.orders_loading = loading
    },
    setOrderDrugs(state, drugs) {
        state.order_drugs = drugs
    },
    setOrdersErrors(state, errors) {
        state.orders_errors = errors
    },
    setOrdersErrorMessage(state, message) {
        state.orders_error_message = message
    },
}

const actions = {
    getOrders({commit}, params) {
        commit('setOrdersLoading', true)
        axios.get('/api/doctors/orders', {params}).then(response => {
            commit('setOrders', response.data)
            commit('setOrdersLoading', false)
        })
    },
    getTodaysOrders({commit},params) {
        commit('setOrders', {})
        commit('setOrdersLoading', true)
        axios.get('/api/doctors/orders/todays', {params}).then(response => {
            commit('setOrders', response.data)
            commit('setOrdersLoading', false)
        })
    },
    getMyOrders({commit}, params) {
        commit('setOrders', {})
        commit('setOrdersLoading', true)
        axios.get('/api/doctors/orders/my', {params}).then(response => {
            commit('setOrders', response.data)
            commit('setOrdersLoading', false)
        })
    },

    async getOrder({commit}, id) {

        commit('setOrdersLoading', true)
        commit('setOrder', {})
        try {
            var response = await axios.get('/api/doctors/orders/' + id + '/show')
            commit('setOrder', response.data.data)
            return true
        } catch (e) {
            commit('setOrdersErrors', e.response.data.errors)
            commit('setOrdersErrorMessage', e.response.data.message)
            return false
        }
    },

    async storeOrder({commit}, order) {
        commit('setOrdersLoading', true)
        try {

            var response = await axios.post('/api/doctors/orders/store', order)
            if (!response.data.ok)
                commit('setOrdersErrors', response.data.errors)
            order.is_creating = false
            commit('setOrder', order)
            return response.data.ok
        } catch (e) {
            commit('setOrdersErrors', e.response.data.errors)
            commit('setOrdersErrorMessage', e.response.data.message)
            return false
        }
    },

    async storeOrderWithoutInfo({commit}, order) {
        commit('setOrdersLoading', true)
        try {

            var response = await axios.post('/api/doctors/orders/store-without-info', order)
            if (!response.data.ok)
                commit('setOrdersErrors', response.data.errors)

            return response.data.ok
        } catch (e) {
            commit('setOrdersErrors', e.response.data.errors)
            commit('setOrdersErrorMessage', e.response.data.message)
            return false
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}