<template>
  <div>

    <h1 class="text-center text-404">403</h1>
    <h3 class="text-center text-404-description">
      Sizga ruxsat berilmagan
    </h3>
    <div class="d-flex justify-center mt-16">
      <div>
        <d-button width="200px" @click="check_user">Qayta tekshirish</d-button>
      </div>
    </div>
  </div>
</template>

<script>
import DButton from "@/components/DButton";

export default {
  name: "PageForbidden",
  components: {
    DButton
  },
  methods: {
    check_user() {
      this.$store.dispatch('check_me').then((res) => {
        if (res) {
          this.$router.push('/')
        }else{
          this.$swal.fire({
            icon: 'error',
            title: 'Sizga ruxsat berilmagan',
            showConfirmButton: false,
            timer: 1500
          })
        }
      })
    }
  }

}
</script>

<style scoped>


</style>