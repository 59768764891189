<template>
  <div>
    <button @mouseenter="focused = true" @mouseout="focused = false" class="d-btn" :style="`${rounded ? 'border-radius: 50rem;':''}${TLg?'padding: 12px;':''} width: ${width}`" @click="$emit('click')" :disabled="disabled">
      <component :is="icon" class="d-button-icon"></component>
      <template v-if="loading">
        <v-progress-circular size="20" indeterminate :color="focused ? 'primary' : 'white'">
        </v-progress-circular>
      </template>
      <template v-else>
      <slot></slot>
      </template>
    </button>
  </div>
</template>

<script>
import { SearchIcon } from "@vue-hero-icons/outline"

export default {
  name: "DButton",
  data(){
    return {
      focused: false
    }
  },
  components: {
    SearchIcon
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ""
    },
    rounded: {
      type: Boolean,
      default: false
    },
    TLg:{
      type: Boolean,
      default: false
    },
    width:{
      type: String,
      default: "100%"
    }
  }
}
</script>

<style scoped>

</style>