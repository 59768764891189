<template>
    <div>
        <v-navigation-drawer
                v-model="drawer"
                width="320"
                color="white"

                style=" height: 90vh; "
        >
            <v-timeline dense>
                <template v-for="item in patient_history">
                    <v-timeline-item small color="gray" v-bind:key="`history_${item.id}`">
                        <order-card :selected="$route.params.recipe_number == item.id" :item="item"></order-card>
                    </v-timeline-item>
                </template>
            </v-timeline>
        </v-navigation-drawer>
        <v-snackbar
                v-model="snackbar"
        >
            Allaqachon tanlangan
        </v-snackbar>
    </div>
</template>

<script>
import OrderCard from "@/components/OrderCard.vue";

export default {
    name: "HistoryCards",
    components: {OrderCard},
    props: {
        expanded: {
            type: Boolean,
            default: true
        },
        open: {
            type: Boolean,
            default: true
        },
    },
    mounted() {
        this.$store.dispatch('getPatientHistory', this.$route.params.id)
    },
    data() {
        return {
            drawer: true,
            logout_dialog: false,
            snackbar: false,
            navigations: null,
            item: {
                id: 1,
                recipe_number: 'IA28008',
                clinics: 'Rustambek Niyat',
                doctor: {
                    first_name: "Abdulla",
                    last_name: "Abdullaev",
                    doctor_type: "Stomatolog",
                    region: "Toshkent viloyati",
                    district: "Bekobod shahar",
                },
                ordered_for: "18. 11. 2023 - 11:30"
            }
        };
    },
    watch: {
        '$route'(oldVal,val) {
          this.navigations = val.meta.navigation
        },
        '$route.params.recipe_number'(){
            this.$store.dispatch('getPatientHistory', this.$route.params.id)
        },
        open(val) {
            this.drawer = val
        }
    },
    computed: {
        patient_history() {
            return this.$store.getters.patient_history && this.$store.getters.patient_history.data ? this.$store.getters.patient_history.data : []
        }
    },
    methods: {
        logout() {
            this.$store.dispatch('logout').then(() => {
                this.$router.push('/login')
            })
        },
    }
}
</script>


<style scoped>
.text-color {
    color: #7F95A3 !important;
}

.text-size {
    font-size: 18px !important;
    line-height: 15px !important;
    padding: 4px 0 !important;
}

.logout_icon {
    font-size: 30px !important;
    margin: 0 10px 0 0 !important;
    padding: 0 !important;
    cursor: pointer;
    width: 20px !important;
    height: 20px !important;
    border-radius: 50%;
}

.logout_icon:hover {
    background: #bbb !important;
    color: white !important;
}

.active {
    background: #01A1B7 !important;
}

.active-color {
    color: white !important;
}

.row {
    display: flex;
    justify-content: center;
    margin: 0;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.flex-row {
    display: flex;
    justify-content: center;
    align-items: center !important;
}

.active-back {
    background: #01A1B7 !important;
    border-radius: 5px;
}

.mr0 {
    margin-right: 0 !important;
}

.v-overlay {
    z-index: 10003 !important;
}

.v-timeline:before {
    left: calc(28px - 1px) !important;
}

</style>