<template>
    <v-app>
        <v-main>
            <slot></slot>
        </v-main>
        <support-component></support-component>
    </v-app>
</template>

<script>
import SupportComponent from "@/components/SupportComponent.vue";

export default {
    name: "LayoutBlank",
    components: {SupportComponent}
}
</script>

<style scoped>

</style>
