import createPersistedState from "vuex-persistedstate";
import SecureLS from 'secure-ls'
import Vue from 'vue'
import Vuex from 'vuex'
import doctor from './doctor'
import helpers from './helpers'
import drugs from './drugs'
import filter from './filter'
import layout from './layout'
import order from './order'
import patient from './patient'


const ls = new SecureLS({isCompression: false});

Vue.use(Vuex)




export default new Vuex.Store({
    modules: {
        patient,
        order,
        layout,
        filter,
        drugs,
        helpers,
        doctor,

    },
    state:{
        title: 'Regions',
        storage: process.env.VUE_APP_STORAGE,
    },
    getters: {
        title: state => state.title,
        storage: state => state.storage
    },
    mutations: {
        setTitle(state, title) {
            state.title = title
        }
    },
    plugins: [
        createPersistedState({
            storage: {
                getItem: key => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: key => ls.remove(key)
            }
        })],
})


