import axios from 'axios'

const state = {
    countries: {},
    region:{},
    district:{},
    regions: {},
    districts: {},
    mfies: {},
    clinics: {},
    drug_companies: {},
    mnns: {},
    drug_types: {},
    helpers_loading: false,
}

const getters = {
    countries: state => state.countries,
    region: state => state.region,
    district: state => state.district,
    mfies: state => state.mfies,
    regions: state => state.regions,
    districts: state => state.districts,
    clinics: state => state.clinics,
    drug_companies: state => state.drug_companies,
    drug_types: state => state.drug_types,
    mnns: state => state.mnns,
    helpers_loading: state => state.helpers_loading,
}

const mutations = {
    setCountries(state, countries) {
        state.countries = countries
    },
    setRegions(state, regions) {
        state.regions = regions
    },
    setDistricts(state, districts) {
        state.districts = districts
    },
    setMfies(state, mfies) {
        state.mfies = mfies
    },
    setClinics(state, clinics) {
        state.clinics = clinics
    },
    setDrugCompanies(state, drug_companies) {
        state.drug_companies = drug_companies
    },
    setDrugTypes(state, drug_types) {
        state.drug_types = drug_types
    },
    setHelpersLoading(state, helpers_loading) {
        state.helpers_loading = helpers_loading
    },
    setRegion(state, region) {
        state.region = region
    },
    setDistrict(state, district) {
        state.district = district
    },
    setMnns(state, mnns) {
        state.mnns = mnns
    }
}

const actions = {
    getRegions({commit}, params) {
        commit('setHelpersLoading', true)
        axios.get('/api/regions', {params})
            .then(response => {
                commit('setRegions', response.data)
                commit('setHelpersLoading', false)
            })
    },
    async getDistrictsByRegion({commit}, id) {
        commit('setHelpersLoading', true)
        axios.get('/api/districts/' + id + '/by-region-id',)
            .then(response => {
                commit('setHelpersLoading', false)
                commit('setDistricts', response.data)
            })
    },
    async getMfiesByDistrict({commit}, id) {
        commit('setHelpersLoading', true)
        axios.get('/api/mfies/' + id + '/by-district-id',)
            .then(response => {
                commit('setHelpersLoading', false)
                commit('setMfies', response.data)
            })
    },
    getClinics({commit}, params) {
        commit('setHelpersLoading', true)
        axios.get('/api/clinics', {params})
            .then(response => {
                commit('setHelpersLoading', false)
                commit('setClinics', response.data)
            })
    },
    getDrugCompanies({commit}, params) {
        commit('setHelpersLoading', true)
        axios.get('/api/drug-companies', {params})
            .then(response => {
                commit('setHelpersLoading', false)
                commit('setDrugCompanies', response.data)
            })
    },
    getDrugTypes({commit}, params) {
        commit('setHelpersLoading', true)
        axios.get('/api/drug-types', {params})
            .then(response => {
                commit('setHelpersLoading', false)
                commit('setDrugTypes', response.data)
            })
    },
    getCountries({commit}, params) {
        commit('setHelpersLoading', true)
        axios.get('/api/countries', {params})
            .then(response => {
                commit('setHelpersLoading', false)
                commit('setCountries', response.data)
            })
    },
    getMnns({commit}, params) {
        commit('setHelpersLoading', true)
        axios.get('/api/mnns', {params})
            .then(response => {
                commit('setHelpersLoading', false)
                commit('setMnns', response.data)
            })
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}